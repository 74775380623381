import React from 'react'
import Layout from 'src/components/Layout'
import { Inner } from 'src/components/layouts/Inner'
import { PageHeading } from 'src/components/molecules/Heading'
import { ElementContainer } from 'src/components/layouts/ElementContainer'
import { useSiteMetadata } from 'src/hooks'
import PageMeta from 'src/components/PageMeta'

const RecruitEntryPage: React.FC = () => {
  const { siteTitle } = useSiteMetadata()

  return (
    <Layout>
      <PageMeta title={`採用エントリー | ${siteTitle}`} />
      <Inner>
        <ElementContainer>
          <PageHeading component="h2" heading="採用エントリー" subHeading="Entry form" />
        </ElementContainer>

        <iframe
          title="entry form"
          src="https://docs.google.com/forms/d/e/1FAIpQLScn4oNPpmJaIs1g4ayOW1sTZSu_QzPruJ_VdSPBixFx43Cdqw/viewform"
          width="100%"
          height="1380"
          frameBorder="0"
          marginHeight={0}
          marginWidth={0}
          scrolling="no"
        >
          読み込んでいます...
        </iframe>
      </Inner>
    </Layout>
  )
}

export default RecruitEntryPage
